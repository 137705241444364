import React from "react"
import { ThemeProvider, css } from "styled-components"
//import { TypographyStyle } from 'react-typography'

//import GlobalStyle from './styles'
//import GlobalHelpers from './helpers'
//import typography from './typography'

const breakpoints = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xlg: 1260,
  mg: 1360,
  xmg: 1760,
}

const palette = {
  textColor: `rgb(37, 37, 38)`,
  greyLight: `rgb(136,136,136)`,
  greyLighter: `#d3d3d3`,
  grey: `rgb(81,81,81)`,
  primary: `rgb(250,170,0)`,
  primaryDark: `rgb(138,79,1)`,
  silver: `#e9e9e9`,
  white: `#ffffff`,
  black: `#000000`,
  red: `#e74a21`,
  blue: `rgb(0, 143, 213)`,
  green: `rgb(120,180,39)`,
  pink: `rgb(208,67,139)`,
  orange: `rgb(248,89,57)`,
  purple: `rgb(147,92,158)`,
  lapis: `#4B59CC`,
  teal: `#0FB5A8`,
}

export const theme = {
  colors: palette,
  breakpoints,
  gutter: `7vmin`,
  maxWidth: "1760px",
  antialias: css`
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `,
}

const nova = (props) => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
)

export default nova
