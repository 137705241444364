import { createGlobalStyle } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import tw from 'twin.macro'

export default createGlobalStyle`

    .prose {
        color: var(--textColor);

        > *:last-child {
            margin-bottom: 0;
        }

        .lead {
            font-size: 1.5em;
            line-height: 1.5;
        }

        h2, h3, h4 {
            font-weight: 600;
        }

        h2:not([class*="text-"]) {
            font-size: 2.25em;
            ${tw `text-3xl lg:text-4xl tracking-tight font-semibold`}
        }

        * + h2,
        * + h3,
        * + h4 {
            margin-top: 1.5em;
        }
        ${breakpoint("sm")`
            .align-left {
                float: left;
                margin-right: 1.5em;
            }
            .align-right {
                float: right;
                margin-right: 1.5em;
            }
        `}

        blockquote {
            font-family: Newzald Bold Italic;
            font-size: 2em;
            line-height: 1.3;
            color: ${(props) => props.theme.colors.blue};
        }

        * + blockquote {
            margin-top: 1em;
            margin-bottom: 1em;
        }

        p, ul, ol, table {
            margin-bottom: 1.25em;
        }

        a {
            color: var(--textColor);
            text-decoration: underline;
            text-underline-offset: .15em;
        }
    }

    * + .prose:not([class*=mt]) {
        margin-top: 2em;
    }

    a {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;

        @media (hover: hover) {
            &:hover, &:focus {
                color: ${(props) => props.theme.colors.primaryDark};
            }
        }
    }


    .theme--primary {
        color: ${(props) => props.theme.colors.primary};
    }

    .theme--blue {
        color: ${(props) => props.theme.colors.blue};
    }

    .theme--green {
        color: ${(props) => props.theme.colors.green};
    }

    .theme--pink {
        color: ${(props) => props.theme.colors.pink};
    }

    .theme--purple {
        color: ${(props) => props.theme.colors.purple};
    }

    .theme--orange {
        color: ${(props) => props.theme.colors.orange};
    }

    .theme--lapis {
        color: ${(props) => props.theme.colors.lapis};
    }

    .theme--teal {
        color: ${(props) => props.theme.colors.teal};
    }
`
