// styleguide/ThemeWrapper.js
import React from "react"
import { ThemeProvider } from "styled-components"
//import { TypographyStyle } from 'react-typography'

import { theme } from "./nova-theme"
//import typography from './typography'

import GlobalCSS from "./global-css"

const Wrapper = ({ children, excludeTypography = false }) => (
  <ThemeProvider theme={theme}>
    <GlobalCSS />
    {children}
  </ThemeProvider>
)
export default Wrapper
