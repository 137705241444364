import React, { useState, useEffect } from "react"

export const NotificationContext = React.createContext()

const localStoragePrefix = "nova-energy"

const NotificationProvider = (props) => {
  const [hideNotification, setHideNotification] = useState(false)

  useEffect(() => {
    setHideNotification(
      JSON.parse(localStorage.getItem(`${localStoragePrefix}__notify`)) || false
    )
  }, [])

  useEffect(() => {
    localStorage.setItem(
      `${localStoragePrefix}__notify`,
      JSON.stringify(hideNotification)
    )
  }, [hideNotification])

  return (
    <NotificationContext.Provider
      value={{
        hideNotification,
        setHideNotification,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
